import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

import Page from '../Page';
import './styles.scss';

import ansku from './ansku.png';

export default function AboutPage() {
  return (
    <Page>
      <Container className="mb-4">
        <Row className="headerRow text-center">
          <Col>
            <h1>Laadukasta hierontaa Porissa</h1>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6} className="mb-4">
            <Card body className="frontCard pros cardContact">
              <h4>Klassisella hieronnalla on monia terveyttä edistäviä vaikutuksia.</h4>
              <p>
                Hieronta muun muassa:
              </p>
              <ul className="fa-ul prosList">
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>poistaa lihaskireyksiä</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>lievittää kipua</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>elvyttää verenkiertoa ja imunestekiertoa</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>nopeuttaa palautumista</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>vähentää stressiä</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>parantaa unen laatua</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>helpottaa masennusoireita</li>
              </ul>
              <Button
                size="lg"
                onClick={() => { window.location.href = '/ajanvaraus'; }}
              >
                VARAA AIKA
              </Button>
            </Card>
          </Col>

          <Col md={6} className="mb-2">
            <Card body className="frontCard cons cardContact">
              <h4 className="mb-3">Hieronta ei sovi, jos sinulla on muun muassa:</h4>
              <ul className="fa-ul consList">
                <li><span className="fa-li"><FontAwesomeIcon icon={faBan} /></span>veritulppa</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faBan} /></span>rikkoutunut iho</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faBan} /></span>uusi vamma</li>
                <li><span className="fa-li"><FontAwesomeIcon icon={faBan} /></span>kova, katkeamaton kipu</li>
              </ul>
              <p>
                Jos epäilet hieronnan sopivuutta, kysy lisää!
                Ensimmäisen hoitokerran yhteydessä tehdään alkututkimus,
                jossa kartoitetaan mahdolliset vasta-aiheet.
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="greyContainer ">
        <Container>
          <Row className="personRow">
            <Col lg="6">
              <FontAwesomeIcon icon={faQuoteRight} className="quoteIcon"/>
              <p className="quote">
                Olen suorittanut koulutetun hierojan ammattitutkinnon
                ja hieronnan erikoisammatti&shy;tutkinnon (urheiluhieroja EAT).
              </p>
              <h5>Koulutetun hierojan ammattitutkinto</h5>
              <p className="footnote">
                Suoritettu Porin Winnovassa 2018. Koulutettu hieroja on nimikesuojattu terveydenhuollon
                ammattihenkilö, joka on suorittanut koulutetun hierojan
                näyttötutkinnon. Koulutetun hierojan työtä ohjaa sosiaali- ja
                terveydenhuollon henkilöstöä koskeva lainsäädäntö.
              </p>
              <h5>Hieronnan erikoisammattitutkinto</h5>
              <p className="footnote">
                Suoritettu Pirkanmaan urheiluhierojakoulussa 2023.
                Tutkinnon osat:
                <ul className="mt-2 mb-4">
                  <li>Urheilijan lihashuolto</li>
                  <li>Alaraajakipuisen potilaan toimintakyvyn edistäminen</li>
                  <li>Yläraajakipuisen potilaan toimintakyvyn edistäminen</li>
                  <li>Niska-hartiaseudun ja pään alueen kipupotilaan toimintakyvyn edistäminen</li>
                </ul>
              </p>
            </Col>
            <Col lg="6" className="d-flex align-items-end">
              <img src={ansku} alt="Ansku" className="personPic" />
            </Col>
          </Row>
        </Container>
      </Container>
    </Page>
  );
};
